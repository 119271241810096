<template>
  <div class="introduce flex" style="padding-bottom: 20px;padding-top: 16px;">
    <div class="commonLeft">
      <NewsList />
      <Contact style="margin-top: 16px;" />
    </div>
    <div class="commonRight">
      <div class="commonRightCnt" style="height: 100%;">
        <div class="commonTle_r">
          <div>
            <img class="icon" style="height: 18px;" src="../assets/team_icon.png" alt="">
          </div>
          <span class="tle">团队集体</span>
        </div>

        <div class="cnt flex" style="padding: 15px 34px;flex-wrap: wrap;justify-content: space-between;">
          <div
            v-for="(item, i) in list"
            :key="i"
            class="list"
            style="cursor: pointer;"
            @click="$router.push('/article/' + item.content_id)"
          >
            <div class="img">
              <img style="width: 246px;height: 364px;" :src="item.cover" alt="">
            </div>
            <p style="font-size: 16px; text-align: center;padding-top: 16px;padding-bottom: 30px;">{{ item.title }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  selectArticleByPage
} from '@/http/api.js'

import NewsList from '@/components/newsList.vue'
import Contact from '@/components/contact.vue'
export default {
  components: {
    NewsList,
    Contact
  },
  data() {
    return {
      list: ''
    }
  },
  created() {
    this._selectArticleByPage()
  },
  methods: {
    _selectArticleByPage() {
      selectArticleByPage({
        resource_code: 'team',
        pageSize: 9999, // 每页多少条
        pageNum: 1 // 第几页
      }).then(res => {
        if (res && res.code === 200) {
          this.list = []
          res.data.forEach(item => {
            const imgUrl = this.$fileUrl + item.cover.split('9090')[1]
            this.list.push({
              ...item,
              cover: imgUrl
            })
          })
        }
      })
    }

  }
}
</script>
<style lang="scss" scoped>
// .lawsRegulations{

// }
</style>
