<template>
  <div class="header">
    <ul>
      <li
        v-for="(item,i) in navList"
        :key="i"
        :class="{isActive: nowNav === item.path}"
        @click="pathTo(item)"
      >
        <span>{{ item.name }}</span>
      </li>
    </ul>
  </div>
</template>
<script>

// import { mapState } from 'vuex'
export default {

  data() {
    return {
      nowNav: '/home'
      // navList: [
      //   {
      //     name: '首页',
      //     path: '/home'
      //   },
      //   {
      //     name: '本所介绍',
      //     path: '/introduce'
      //   },
      //   {
      //     name: '新闻动态',
      //     path: '/news'
      //   },
      //   {
      //     name: '团队集体',
      //     path: '/teams'
      //   },
      //   {
      //     name: '员工风采',
      //     path: '/employeeStyle'
      //   },
      //   {
      //     name: '典型案例',
      //     path: '/classicCase'
      //   },
      //   {
      //     name: '服务范围',
      //     path: '/serviceScope'
      //   },
      //   {
      //     name: '法律法规',
      //     path: '/lawsRegulations'
      //   },
      //   {
      //     name: '人才招聘',
      //     path: '/talentRecruitment'
      //   },
      //   {
      //     name: '联系我们',
      //     path: '/contactUs'
      //   }
      // ]
    }
  },
  computed: {
    navList() {
      const arr = []
      this.$store.state.navList.forEach(item => {
        if (item.resources_url && item.resources_url.indexOf('acticleList') > -1) {
          // routes[0].children.push({
          //   path: '/acticleList/:resourceCode',
          //   component: componentList[item.resources_url]
          // })
          arr.push({
            name: item.resources_name,
            path: '/acticleList/' + item.resources_code
          })
        } else {
          arr.push({
            name: item.resources_name,
            path: '/' + item.resources_code
          })
        }
      })
      return arr
    }
    // ...mapState(['navList', 'showTeacherIndex'])
  },
  watch: {
    '$route': {
      deep: true,
      handler(val) {
        // console.log(val)
        const arr = this.navList.filter(a => a.path === val.path)
        if (arr && arr[0]) {
          this.nowNav = arr[0].path
        }
      }
    }
  },
  created() {
    this.nowNav = this.$route.path
  },
  methods: {
    pathTo(item) {
      // console.log(this.$route.path)
      if (item.path === this.$route.path) {
        return
      }
      this.nowNav = item.path
      this.$router.push(item.path)
    }
    // ...mapActions(['setState']),
    // toIndex() {
    //   if (this.$route.name !== 'Index') {
    //     this.$router.push('/')
    //   }
    // },
    // toTeacher() {
    //   this.$router.push({
    //     name: 'Teacher',
    //     query: this.$route.query
    //   })
    // }
  }
}
</script>
<style lang="scss" scoped>
  .header{
    height: 56px;
    background: linear-gradient( 180deg, #228AD1 0%, #20BBE9 100%);
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.25);
    border-radius: 4px;
    font-size: $titleSize;
    ul{
      display: flex;
      align-items: center;
      li{
        width: 120px;
        position: relative;
        line-height: 56px;
        height: 56px;
        text-align: center;
        cursor: pointer;
        &::after{
          content: "";
          position: absolute;
          height: 20px;
          top: 18px;
          right: 0;
          border-right: 1px solid #fff;
        }
        &.isActive{
          span{
            display: inline-block;
            height: 48px;
            width: 112px;
            margin: 0 auto;
            background: rgba(255,255,255,0.75);
            border-radius: 4px;
            color: #2B4B94;
            line-height: 48px;
          }
        }
        &:last-child{
          &::after{
            display: none;
          }
        }
      }
    }
  }
</style>
