
export const common = {

  dateFormat(fmt, date) {
    let ret
    const opt = {
      'y+': date.getFullYear().toString(), // 年
      'M+': (date.getMonth() + 1).toString(), // 月
      'd+': date.getDate().toString(), // 日
      'H+': date.getHours().toString(), // 时
      'm+': date.getMinutes().toString(), // 分
      's+': date.getSeconds().toString() // 秒
      // 有其他格式化字符需求可以继续添加，必须转化成字符串
    }
    for (const k in opt) {
      ret = new RegExp('(' + k + ')').exec(fmt)
      if (ret) {
        fmt = fmt.replace(ret[1], (ret[1].length === 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, '0')))
      }
    }
    return fmt
  },
  getRandomChar: (num) => { // 随机返回num位字符
    let randomStr = ''
    // eslint-disable-next-line no-array-constructor
    const arr = new Array(0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R’, ‘S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z')
    for (let i = 0; i < num; i++) {
      const index = Math.floor(Math.random() * 62)
      randomStr += arr[index]
    }
    return randomStr
  }, // 随机返回num位字符
  getRandom: (num) => { // 随机返回num位字符
    let randomStr = ''
    // eslint-disable-next-line no-array-constructor
    const arr = new Array(0, 1, 2, 3, 4, 5, 6, 7, 8, 9)
    for (let i = 0; i < num; i++) {
      const index = Math.floor(Math.random() * 10)
      randomStr += arr[index]
    }
    return randomStr
  }, // 随机返回num位字符

  /* 设置cookie*/
  setCookie(cookieName, value, expire, unit) {
    const c_name = cookieName === 'token' ? 'hgd_token' : cookieName
    expire = 1
    unit = 'd'
    const exdate = new Date()
    if (unit === 'm') {
      exdate.setMinutes(exdate.getMinutes() + expire) // 保存的分钟数  //getMinutes()获取当前分钟数(0-59)
    } else if (unit === 'h') {
      exdate.setHours(exdate.getHours() + expire) // 保存的小时数  //getHours()获取当前小时数(0-23)
    } else if (unit === 'd') {
      exdate.setDate(exdate.getDate() + expire) // 保存的天数    //getDate()获取当前日(1-31)
    } else {
      exdate.setTime(exdate.getTime() + 1000 * expire) // 保存的秒数    //getTime()获取当前时间this.$router.push({path:(从1970.1.1开始的毫秒数)
    }
    const pathArr = window.location.origin.split('/')
    let path = ''
    if (pathArr.length > 3) {
      path = pathArr[3]
    }

    window.document.cookie = c_name + '=' + escape(value) + ';expires=' + exdate.toGMTString() + ';Path=/' + path + ';'
    // if (sessionStorage.httpType === 'http') {
    //   // window.document.cookie = c_name + "=" + escape(value) + ";Path=/" + path + ";";
    //   window.document.cookie = c_name + '=' + escape(value) + ';expires=' + exdate.toGMTString() + ';Path=/' + path + ';'
    // } else {
    //   window.document.cookie = c_name + '=' + escape(value) + ';Path=/' + path + ';Secure;'
    // }
  },
  /* 获取cookie*/
  getCookie(cookieName) {
    // const c_name = cookieName === 'token' ? 'hgd_token' : cookieName
    const c_name = cookieName
    if (document.cookie.length > 0) {
      let c_start = document.cookie.indexOf(c_name + '=')
      if (c_start !== -1) {
        c_start = c_start + c_name.length + 1
        let c_end = document.cookie.indexOf(';', c_start)
        if (c_end === -1) c_end = document.cookie.length
        return unescape(document.cookie.substring(c_start, c_end))
      }
    }
    return ''
  },
  /* 删除cookie*/
  delCookie(c_name) {
    const exp = new Date()
    exp.setTime(exp.getTime() + 1)
    document.cookie = c_name + '=null;expires=' + exp.toGMTString()
  },
  /* 清除所有cookie函数*/
  clearAllCookie() {
    const keys = document.cookie.match(/[^ =;]+(?=\=)/g)
    if (keys) {
      for (let i = keys.length; i--;) {
        document.cookie = keys[i] + '=null;expires=' + new Date(0).toUTCString()
      }
    }
  }
}

