<template>
  <div class="introduce flex" style="padding-bottom: 20px;padding-top: 16px;">
    <div class="commonLeft">
      <NewsList />
      <Contact style="margin-top: 16px;" />
    </div>
    <div class="commonRight">
      <div class="commonRightCnt" style="height: 100%;">
        <div class="commonTle_r">
          <div>
            <img class="icon" style="height: 18px;" src="../assets/fwfw.png" alt="">
          </div>
          <span class="tle">本所介绍</span>
        </div>

        <div class="cnt">
          <!-- <SwiperGroup style="height: 100%;width: 100%;" :swiper-list="fwfwList" /> -->
          <div style="line-height: 32px;padding: 22px 15px;" v-html="content" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  selectArticleByOne,
  selectArticleById
} from '@/http/api.js'

import NewsList from '@/components/newsList.vue'
import Contact from '@/components/contact.vue'
export default {
  components: {
    NewsList,
    Contact
  },
  data() {
    return {
      content: ''
    }
  },
  created() {
    this._selectArticleByOne()
  },
  methods: {
    _selectArticleByOne() {
      selectArticleByOne({
        resource_code: 'introduce'
      }).then(res => {
        if (res && res.code === 200) {
          selectArticleById({ content_id: res.data.content_id }).then(newRes => {
            this.content = newRes.data.content
          })
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.lawsRegulations{

}
</style>
