import Vue from 'vue'
import Vuex from 'vuex'

import {
  getAllMenu, selectArticleByOne,
  selectArticleById
} from '@/http/api.js'
// const img1 = require('@/assets/del/index_1.png')
// const img2 = require('@/assets/del/index_2.png')
// const img3 = require('@/assets/del/index_3.png')

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    navList: [],
    contactContent: ''
  },
  mutations: {
  },
  actions: {
    getContactContent({ state }) {
      selectArticleByOne({
        resource_code: 'contact'
      }).then(res => {
        if (res && res.code === 200) {
          selectArticleById({ content_id: res.data.content_id }).then(newRes => {
            state.contactContent = newRes.data.content
          })
        }
      })
    },
    async getNavList({ state }, fn) {
      await getAllMenu().then(res => {
        state.navList = res.data
        if (fn) {
          fn(res.data)
        }
      })
    },
    setState({ state }, params) {
      Object.keys(params).forEach(item => {
        state[item] = params[item]
      })
    }
  },
  modules: {
  }
})
