import { htServer } from './index'
import qs from 'qs'
// 获取菜单
export async function getAllMenu(body) {
  const res = await htServer.post('/bcs/front/menu/selectAllNavigationList', qs.stringify(body))
  return res
}
// 获取新闻分页列表
export async function selectArticleByPage(body) {
  const res = await htServer.post('/bcs/front/article/selectArticleByPage', qs.stringify(body))
  return res
}
// 根据ID获取单条内容
export async function selectArticleById(body) {
  const res = await htServer.post('/bcs/front/article/selectArticleById', qs.stringify(body))
  return res
}
// 根据栏目获取第一条信息
export async function selectArticleByOne(body) {
  const res = await htServer.post('/bcs/front/article/selectArticleByOne', qs.stringify(body))
  return res
}
// 根据ID获取单条内容
export async function selectArticleByCode(body) {
  const res = await htServer.post('/bcs/front/article/selectArticleByCode', qs.stringify(body))
  return res
}
