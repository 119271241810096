<template>
  <div class="swiper">
    <swiper
      v-if="swiperList&&swiperList.length"
      ref="mySwiper"
      class="mySwiper"
      style="height: 100%;"
      :options="swiperOptions"
    >
      <swiper-slide
        v-for="(item, index) in swiperList"
        :key="index"
      >
        <img
          :data-card="JSON.stringify(item)"
          style="width: 100%;height: calc(100% - 30px);cursor: pointer;"
          :src="item.src"
        >
        <p style="cursor: pointer;" :data-card="JSON.stringify(item)" class="name">{{ item.name }}</p>
      </swiper-slide>
      <!-- <div
        slot="pagination"
        class="swiper-pagination"
      /> -->
    </swiper>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  components: {
    Swiper,
    SwiperSlide
  },
  props: {
    swiperList: {
      default: () => {
        return []
      },
      type: Array
    }
  },
  data() {
    return {
      swiperOptions: {
        // 自动切换
        autoplay: {
          delay: 3000,
          disableOnInteraction: false
        },
        // 环路
        loop: true,
        // loopedSlides: 5, //
        slidesPerView: 5, // 页面显示的slides数量
        spaceBetween: 16,
        // 前进后退按钮
        // navigation: {
        //   nextEl: '.swiper-button-next',
        //   prevEl: '.swiper-button-prev'
        // },
        // 分页器
        // pagination: {
        //   el: '.swiper-pagination',
        //   clickable: true,
        //   bulletClass: 'my-bullet',
        //   bulletActiveClass: 'my-bullet-active',
        //   renderBullet: function(index, className) {
        //     // console.log(index, className)
        //     return `<span class="${className}">${index + 1}</span>`
        //   }
        // },
        // 切换效果cube
        // effect: 'cube',
        on: {
          click: (e) => {
            this.$emit('swiperClick', e)
          }
        },
        cubeEffect: {
          slideShadows: true,
          shadow: true,
          shadowOffset: 100,
          shadowScale: 0.7
        }
      }
    }
  }
}
</script>
<style lang="scss">
.mySwiper{
  .swiper-pagination{
    width: auto;
    left: auto;
    right: 0;
    padding-right: 10px;
    .my-bullet{
      display: inline-block;
      width: 24px;
      height: 24px;
      background: $whiteColor;
      border-radius: 2px;
      margin-right: 5px;
      line-height: 24px;
      text-align: center;
      color: #595959;
      cursor: pointer;
      &:last-child{
        margin-right: 0;
      }
      &.my-bullet-active{
        background: #FF7E2F;
        color: $whiteColor;
      }
    }
  }
}

</style>
<style lang="scss" scoped>
.mySwiper{
  .name{
    font-size: $smallFontSize;
    padding-top: 12px;
    text-align: center;
  }
}
</style>
