<template>
  <div class="acticleList flex" style="padding-bottom: 20px;padding-top: 16px;">
    <div class="commonLeft">
      <NewsList />
      <Contact style="margin-top: 16px;" />
    </div>
    <div class="commonRight">
      <div class="commonRightCnt" style="height: 100%;">
        <div class="commonTle_r">
          <div>
            <img class="icon" style="height: 18px;" :src="icon" alt="">
          </div>
          <span class="tle">{{ resourceName }}</span>
        </div>

        <div class="cnt">
          <ul v-if="acticleList&&acticleList.length" class="listWrapper">
            <li
              v-for="(item,i) in acticleList"
              :key="i"
              :title="item.title"
              class="list"
              @click="$router.push('/article/' + item.content_id)"
            >
              <div class="text">
                {{ item.title }}
                <img v-if="item.news_days<4" class="new" src="../assets/new_icon.png" alt="">
              </div>
              <div class="date">{{ item.create_time }}</div>
            </li>
          </ul>
          <div v-else class="empty">
            没有符合条件的数据
          </div>

          <div v-if="total" class="pagination">
            <el-button type="text" :disabled="pageNum===1" @click="handleCurrentChange(1)">首页</el-button>
            <el-pagination
              layout="prev, pager, next"
              :page-size="pageSize"
              :current-page.sync="pageNum"
              :total="total"
              prev-text="上一页"
              next-text="下一页"
              @current-change="handleCurrentChange"
            />
            <el-button :disabled="Math.ceil(total / pageSize) === pageNum" type="text" class="" @click="handleCurrentChange(Math.ceil(total / pageSize))">尾页</el-button>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
import NewsList from '@/components/newsList.vue'
import Contact from '@/components/contact.vue'
import { mapState } from 'vuex'
// import { mapState } from 'vuex'
import {
  selectArticleByPage
} from '@/http/api.js'
export default {
  components: {
    NewsList,
    Contact
  },
  data() {
    return {
      resourceCode: '',
      resourceName: '',
      icon: '',
      // resource_code: 'express',
      pageSize: 13, // 每页多少条
      pageNum: 1, // 第几页
      total: 0,
      acticleList: []
    }
  },
  computed: {
    ...mapState(['navList'])
  },
  watch: {
    '$route': {
      deep: true,
      handler(val) {
        // console.log(val)
        this.initData()
      }
    }
  },
  created() {
    this.initData()
  },
  methods: {
    initData() {
      this.resourceCode = this.$route.params.resourceCode
      const { parentCode } = this.$route.query
      if (parentCode) {
        this.resourceName = this.navList.filter(a => a.resources_code === parentCode)[0].children.filter(a => a.resources_code === this.resourceCode)[0].resources_name
      } else {
        this.resourceName = this.navList.filter(a => a.resources_code === this.resourceCode)[0].resources_name
      }

      try {
        this.icon = require(`@/assets/${this.resourceCode}_icon.png`)
      } catch {
        this.icon = ''
      }
      this.getList()
    },
    handleCurrentChange(val) {
      this.pageNum = val
      this.getList()
    },
    getList() {
      selectArticleByPage({
        resource_code: this.resourceCode,
        pageSize: this.pageSize, // 每页多少条
        pageNum: this.pageNum // 第几页
      }).then(res => {
        if (res && res.code === 200) {
          this.acticleList = res.data
          this.total = res.total
        } else {
          this.total = 0
          this.acticleList = []
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.acticleList{
  .empty{
    padding-top: 20px;
    line-height: 50px;
    text-align: center;
    color: #666;
  }
  .listWrapper{
    padding: 20px 30px;
    padding-bottom: 10px;
    li{
      line-height: 40px;
      border-bottom: 1px dashed #219EDA;
      .date{
        padding-right: 5px;
      }
    }
  }
}
</style>
