<template>
  <div class="article">
    <div class="title">{{ title }}</div>
    <div class="wylsArticle" style="padding: 20px 15px;line-height: 32px;" v-html="content" />
  </div>
</template>
<script>
import {
  selectArticleById
} from '@/http/api.js'

export default {
  data() {
    return {
      content_id: '1',
      content: '',
      title: ''
    }
  },
  created() {
    this.content_id = this.$route.params.content_id
    // console.log()
    this._selectArticleById()
  },
  methods: {
    _selectArticleById() {
      selectArticleById({
        content_id: this.content_id
      }).then(res => {
        if (res.code === 200) {
          this.content = res.data.content
          this.title = res.data.title
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.article{
  .title{
    font-size: 24px;
    color: #333;
    text-align: center;
    font-weight: bold;
    line-height: 30px;
    padding-top: 20px;
  }
}
</style>
<style lang="scss">
.wylsArticle{
  color: #333;
}
</style>
