<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  data() {
    return {
      times: this.$parseTime(new Date(), '{y}-{m}-{d} {h}:{i}'),
      timer: null,
      headerTitle: '',
      showHeader: true,
      isSys: false
    }
  },
  watch: {
    '$route'(to, from) {
      if (to.name === 'Index') {
        this.isSys = true
      } else {
        this.isSys = false
      }
      if (to.name === 'Login') {
        this.showHeader = false
      } else {
        this.showHeader = true
      }
      this.headerTitle = to.meta.headerTitle || to.query.experimentName || ''
    }
  },
  created() {
    if (!this.timer) {
      this.timer = setInterval(() => {
        this.times = this.$parseTime(new Date(), '{y}-{m}-{d} {h}:{i}')
      }, 1000)
    }
    const userMsg = this.$common.getCookie('YNHGDuserMsg')
    if (userMsg) {
      sessionStorage.setItem('userMsg', userMsg)
    }
  },
  methods: {
    to(path) {
      this.$router.push({
        path: path
      })
    }
  }
}
</script>
<style lang="scss">
  body{
    width: auto;
    min-width: 100%;
  }
  #app{
    // background: #f6f6f6;
    // min-height: 100vh;
  }
  img{
    user-select: none;
  }

</style>
