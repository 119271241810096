<template>
  <div class="newsList commonLeft" style="height: 249px;">
    <div class="commonTle_l">
      <img class="icon" src="../assets/zxgg_icon.png" alt=""><span>最新公告</span>
    </div>
    <div class="cnt">
      <ul class="listWrapper" style="padding-top: 5px;">
        <li
          v-for="(item,i) in newsList"
          :key="i"
          :title="item.title"
          class="list"
          @click="$router.push('/article/' + item.content_id)"
        >
          <img src="../assets/list_icon2.png" alt="">
          <div class="text">
            {{ item.title }}
            <img v-if="item.news_days<4" class="new" src="../assets/new_icon.png" alt="">
          </div>
          <!-- <div class="date" style="padding-right: 50px;">{{ item.create_time }}</div> -->
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import {
  selectArticleByPage
} from '@/http/api.js'
export default {
  data() {
    return {
      newsList: []
    }
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      selectArticleByPage({
        resource_code: 'notice',
        pageSize: 5, // 每页多少条
        pageNum: 1 // 第几页
      }).then(res => {
        if (res && res.code === 200) {
          this.newsList = res.data
        }
      })
    }
  }
}
</script>
