<template>
  <div class="newsList commonLeft" style="height: 308px;">
    <div class="commonTle_l">
      <img class="icon" src="../assets/contact_l_icon.png" alt=""><span>联系方式</span>
    </div>
    <div class="cnt" style="line-height: 34px; padding: 15px 20px;" v-html="contactContent" />
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  data() {
    return {
      newsList: []
    }
  },
  computed: {
    ...mapState(['contactContent'])
  },
  created() {
    // this.getList()
  },
  methods: {
    // getList() {
    //   selectArticleByPage({
    //     resource_code: 'news',
    //     pageSize: 8, // 每页多少条
    //     pageNum: 1 // 第几页
    //   }).then(res => {
    //     if (res && res.code === 200) {
    //       this.newsList = res.data
    //     }
    //   })
    // }
  }
}
</script>
