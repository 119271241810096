<template>
  <div class="home">
    <div class="banner">
      <Swiper style="height: 384px;" :swiper-list="banner.list" />
    </div>
    <div class="flex" style="margin:20px 0;">
      <div class="commonLeft bsjs_l" style="height: 376px;">
        <div class="commonTle_l">
          <img class="icon" src="../assets/bsjs_icon.png" alt=""><span>本所介绍</span>
        </div>
        <div class="cnt" style="padding: 22px 0;">
          <div class="overflowAuto" style="line-height: 32px;height: 100%;padding: 0 15px;" v-html="bsjsCnt.data" />
        </div>
      </div>
      <div class="commonRight">
        <div class="commonRightCnt" style="height: 376px;">
          <div class="commonTle_r">
            <div>
              <img class="icon" style="height: 18px;" src="../assets/express_icon.png" alt="">
            </div>
            <span class="tle">新法速递</span>

            <span class="more" @click="$router.push('/acticleList/express?parentCode=home')">更多>></span>
          </div>
          <div class="cnt flex xfsd" style="display: flex;">

            <ul class="listWrapper" style="width: 637px;padding-top: 15px;">
              <li
                v-for="(item,i) in xfsdList"
                :key="i"
                class="list"
                :title="item.title"
                @click="$router.push('/article/' + item.content_id)"
              >
                <img src="../assets/list_icon1.png" alt="">
                <div class="text">
                  {{ item.title }}
                  <img v-if="item.news_days<4" class="new" src="../assets/new_icon.png" alt="">
                </div>
                <div class="date" style="padding-right: 50px;">{{ item.create_time }}</div>
              </li>
            </ul>
            <div class="xfsd_r">
              <div class="ly">
                <img style="width: 51px;" src="../assets/xfsd_ly.png" alt="">
                在线留言
              </div>
              <div class="ry" @click="$router.push('/acticleList/honor?parentCode=home')">
                <img style="width: 42px;" src="../assets/xfsd_ry.png" alt="">
                资质荣誉
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div>
      <img :src="centerImg.list[0].src" style="width: 1200px;height: auto;object-fit: cover;" alt="">
    </div>
    <div style="margin-top: 20px; padding-bottom: 20px;" class="flex">
      <div class="commonLeft">
        <NewsList />
        <Notice style="margin-top: 16px;" />
        <Contact style="margin-top: 16px;height: 335px;" />
      </div>

      <div class="commonRight">
        <div class="commonRightCnt" style="height: 292px;">
          <div class="commonTle_r">
            <div>
              <img class="icon" style="height: 18px;" src="../assets/fwfw.png" alt="">
            </div>
            <span class="tle">服务范围</span>
            <span class="more" @click="$router.push('/acticleList/service')">更多>></span>
          </div>

          <div class="cnt flex fwfw">
            <div style="width:391px; padding: 6px; height: 100%;">
              <Swiper style="height: 100%;width: 100%;" :swiper-list="serviceBanner.list" />
            </div>
            <div style="flex: 1;line-height: 32px;padding: 22px 15px;" v-html="fwfwContent" />

          </div>
        </div>
        <div class="flex" style="margin-top: 16px;justify-content: space-between;">
          <div class="commonRightCnt" style="width: 430px; height: 303px;">
            <div class="commonTle_r">
              <div>
                <img class="icon" style="height: 18px;" src="../assets/laws_icon.png" alt="">
              </div>
              <span class="tle">法律法规</span>
              <span class="more" @click="$router.push('/acticleList/laws')">更多>></span>
            </div>

            <div class="cnt">
              <ul class="listWrapper">
                <li
                  v-for="(item,i) in flfgCnt.list"
                  :key="i"
                  class="list"
                  :title="item.title"
                  @click="$router.push('/article/' + item.content_id)"
                >
                  <img src="../assets/list_icon3.png" alt="">
                  <div class="text">
                    {{ item.title }}
                    <img v-if="item.news_days<4" class="new" src="../assets/new_icon.png" alt="">
                  </div>
                  <!-- <div class="date" style="padding-right: 50px;">{{ item.create_time }}</div> -->
                </li>
              </ul>

            </div>
          </div>
          <div class="commonRightCnt" style="width: 430px; height: 303px;">
            <div class="commonTle_r">
              <div>
                <img class="icon" style="height: 18px;" src="../assets/papers_icon.png" alt="">
              </div>
              <span class="tle">法律文书</span>
              <span class="more" @click="$router.push('/acticleList/papers?parentCode=home')">更多>></span>
            </div>

            <div class="cnt">
              <ul class="listWrapper">
                <li
                  v-for="(item,i) in flwsCnt.list"
                  :key="i"
                  class="list"
                  :title="item.title"
                  @click="$router.push('/article/' + item.content_id)"
                >
                  <img src="../assets/list_icon3.png" alt="">
                  <div class="text">
                    {{ item.title }}
                    <img v-if="item.news_days<4" class="new" src="../assets/new_icon.png" alt="">
                  </div>
                  <!-- <div class="date" style="padding-right: 50px;">{{ item.create_time }}</div> -->
                </li>
              </ul>

            </div>
          </div>
        </div>
        <div class="commonRightCnt" style="height: 335px;margin-top: 16px;">
          <div class="commonTle_r">
            <div>
              <img class="icon" style="height: 18px;" src="../assets/wyls_icon.png" alt="">
            </div>
            <span class="tle">望岳律师</span>
            <span class="more" @click="$router.push('/team')">更多>></span>
          </div>

          <div class="cnt flex fwfw" style="width: 876px;padding: 15px 0;">
            <SwiperGroup style="height: 100%;width: 100%;" :swiper-list="teamCnt.swiperList" @swiperClick="swiperGroupClick" />

          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import Swiper from '@/components/swiper.vue'
import SwiperGroup from '@/components/swiperGroup.vue'
import NewsList from '@/components/newsList.vue'
import Notice from '@/components/notice.vue'
import Contact from '@/components/contact.vue'
import {
  selectArticleByPage,
  selectArticleByOne,
  selectArticleById,
  selectArticleByCode
} from '@/http/api.js'
export default {
  components: {
    Swiper,
    NewsList,
    Notice,
    Contact,
    SwiperGroup
  },
  data() {
    return {
      xfsdParams: {
        resource_code: 'express',
        pageSize: 7, // 每页多少条
        pageNum: 1 // 第几页
      },
      xfsdList: [
      ],
      fwfwParams: {
        resource_code: 'service'
      },
      serviceBanner: {
        code: 'homeServiceBanner',
        list: []
      },
      banner: {
        code: 'homeBanner',
        list: []
      },
      centerImg: {
        code: 'homeCenterImg',
        list: [
          { src: '' }
        ]
      },
      fwfwContent: '',
      swiperList: [

      ],
      flfgCnt: {
        params: {
          resource_code: 'laws',
          pageSize: 7, // 每页多少条
          pageNum: 1 // 第几页
        },
        list: []
      },
      flwsCnt: {
        params: {
          resource_code: 'papers',
          pageSize: 7, // 每页多少条
          pageNum: 1 // 第几页
        },
        list: []
      },
      bsjsCnt: {
        params: {
          resource_code: 'introduce'
        },
        data: ''
      },
      teamCnt: {
        params: {
          resource_code: 'team',
          pageSize: 9999,
          pageNum: 1
        },
        list: [],
        swiperList: []
      }
    }
  },
  created() {
    this._selectArticleByPage(this.xfsdParams, 'xfsdList')
    this._selectArticleByPage(this.flfgCnt.params, 'flfgCnt.list')
    this._selectArticleByPage(this.flwsCnt.params, 'flwsCnt.list')
    this._selectArticleByPage(this.teamCnt.params, 'teamCnt.swiperList', 'isSwiper')
    // 	首页banner轮播图
    this._selectArticleByCode(this.banner.code, 'banner.list')
    // 	首页中间配图
    this._selectArticleByCode(this.centerImg.code, 'centerImg.list')
    // 	首页服务范围轮播图
    this._selectArticleByCode(this.serviceBanner.code, 'serviceBanner.list')

    this._selectArticleByOne(this.fwfwParams, 'fwfwContent')
    this._selectArticleByOne(this.bsjsCnt.params, 'bsjsCnt.data')
  },
  methods: {
    swiperGroupClick(e) {
      const cardDataJSON = e.target.getAttribute('data-card')
      // console.log(e.target.dataset.card, e.target.getAttribute('data-card'))
      const cardData = JSON.parse(cardDataJSON)
      this.$router.push('/article/' + cardData.content_id)
      // console.log(cardData)
    },
    _selectArticleByOne(params, data) {
      selectArticleByOne(params).then(res => {
        if (res && res.code === 200) {
          selectArticleById({ content_id: res.data.content_id }).then(newRes => {
            if (data.indexOf('.') > -1) {
              const arr = data.split('.')
              this[arr[0]][arr[1]] = newRes.data.content
            } else {
              this[data] = newRes.data.content
            }
          })
        }
      })
    },
    _selectArticleByPage(params, data, type) {
      selectArticleByPage(params).then(res => {
        if (res && res.code === 200) {
          if (data.indexOf('.') > -1) {
            const arr = data.split('.')
            if (type === 'isSwiper') {
              const swiperList = []
              res.data.forEach(item => {
                // console.log(this.$fileUrl)
                const imgUrl = this.$fileUrl + item.cover.split('9090')[1]
                swiperList.push({
                  name: item.title,
                  src: imgUrl,
                  content_id: item.content_id
                })
              })
              this[arr[0]][arr[1]] = swiperList
            } else {
              this[arr[0]][arr[1]] = res.data
            }
          } else {
            this[data] = res.data
          }
        }
      })
    },
    _selectArticleByCode(code, data) {
      selectArticleByCode({
        content_code: code
      }).then(res => {
        if (res && res.code === 200) {
          if (data.indexOf('.') > -1) {
            const arr = data.split('.')
            if (res.data) {
              const imgList = JSON.parse(res.data.content)
              const list = []
              imgList.forEach(item => {
                const imgUrl = this.$fileUrl + item.split('9090')[1]
                list.push({ src: imgUrl })
              })
              this[arr[0]][arr[1]] = list
            }
          }
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.home{
  // background: $whiteColor;
  .bsjs_l{
    height: 376px;
    .text{
      padding: 0 24px;
      padding-top: 5px;
      line-height: 31px;
    }
  }
  .xfsd{
    .listWrapper{
      .list{
        line-height: 40px;
      }
    }
    .xfsd_r{
      padding-top: 52px;
      cursor: pointer;
      >div{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 211px;
        height: 82px;
        background: linear-gradient( 180deg, #FBFDFC 0%, #E9F1FA 100%);
        border-radius: 8px;
        border: 2px solid #C6DEED;

        font-weight: bold;
        font-size: 22px;
        color: #306CB4;
        cursor: pointer;
        img{
          margin-right: 14px;
        }
        &:first-child{
          margin-bottom: 24px;
        }
      }
    }
  }
}
</style>
