<template>
  <div class="main">
    <div class="top">
      <div class="cnt">
        <!-- <div class="btns">
          <div class="fr">
            <span style="margin-right: 22px;">设为首页</span>
            <span>加入收藏</span>
          </div>
        </div> -->
        <div class="logo">
          <img style="width: 547px;" src="../../assets/logo.png" alt="">
          <!-- <span>山东望岳律师事务所</span> -->
        </div>
        <Header class="header" />
      </div>
    </div>

    <div class="content_wrapper">
      <router-view />
    </div>
    <Footer class="footer" />
  </div>
</template>
<script>
import Header from './header.vue'
import Footer from './footer.vue'
import { mapActions } from 'vuex'
export default {
  components: {
    Header,
    Footer
  },
  data() {
    return {
    }
  },
  created() {
    this.getContactContent()
  },
  methods: {
    ...mapActions(['getContactContent'])
  }
}
</script>
<style  lang="scss" scoped>
.main {
  color: $mainFontColor;
  background: $mainBg;
  font-size: $mainFontSize;
  // min-height: 100vh;
  // height: 100vh;
  padding-bottom: 25px;
  a{
    color: $mainFontColor;
  }
  .top{
    color: $whiteColor;
    height: 230px;
    background: linear-gradient( 180deg, #142F6B 0%, #3E62B7 100%);
    .cnt{
      .btns{
        height: 41px;
        padding-top: 24px;
        span{
          font-size: $smallFontSize;
          cursor: pointer;
        }
      }
      .logo{
        height: 123px;
        span{
          font-family: TRENDS;
        }
      }
    }
  }
  .content_wrapper{
    min-height: calc(100vh - 230px - 90px - 25px);
  }
  .content_wrapper, .footer,.cnt{
    width:  $mainWidth;
    margin: 0 auto;
  }
}
</style>
