
import Vue from 'vue'

import { common } from '@/utils/common.js'

const parseTime = (time, cFormat) => {
  // eslint-disable-next-line no-undef
  if (arguments.length === 0) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if (('' + time).length === 10) time = parseInt(time) * 1000
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key]
    if (key === 'a') return ['日', '一', '二', '三', '四', '五', '六'][value]
    if (result.length > 0 && value < 10) {
      value = '0' + value
    }
    return value || 0
  })
  return time_str
}

// 导出excel lak的报警数据使用  tws的对象监控使用  其他模块也可能会添加报警页面
import XLSX from 'xlsx'
const outputXlsxFile = (data, wscols, xlsxName) => {
  /* convert state to workbook */
  const ws = XLSX.utils.aoa_to_sheet(data)
  ws['!cols'] = wscols

  const wb = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(wb, ws, xlsxName)
  /* generate file and send to client */
  XLSX.writeFile(wb, xlsxName + '.xlsx')
}
const readXlsxFile = (file) => {
  console.log(file)
  return new Promise(resolve => {
    const reader = new FileReader()
    reader.readAsBinaryString(file)
    reader.onload = ev => {
      resolve(ev.target.result)
    }
  })
}

let origin = window.location.origin

if (origin.indexOf('localhost') > -1 || origin.indexOf('127.0.0.1') > -1) {
  origin = 'http://192.168.11.15'
}
Vue.prototype.$outputXlsxFile = outputXlsxFile
Vue.prototype.$readXlsxFile = readXlsxFile

Vue.prototype.$common = common
Vue.prototype.$parseTime = parseTime

Vue.prototype.$fileUrl = origin + ':9090'

