<template>
  <div class="contactFooter" v-html="contactContent" />
  <!-- <div style="margin-bottom: 18px;">
      <span>版权所有:山东望岳律师事务所</span>
      <span>地址:山东省泰安市泰安市货场路苹果小区门口</span>
      <span>邮编:271000  鲁ICP备14014760号</span>
    </div>
    <div>
      <span>电话:0538-6110977; 0538-6110799</span>
      <span>传真:0538-6110166</span>
      <span>E-mai ail:sdwyls@163.com</span>
      <span>网址:www.wangyuelawyer.cn</span>
    </div> -->
</template>
<script>
import { mapState } from 'vuex'
export default {
  data() {
    return {

    }
  },
  computed: {
    ...mapState(['contactContent'])
  }
}
</script>
<style lang="scss" >
.contactFooter{
  height: 90px;
  background: #F5F9FF;
  border-radius: 8px;
  border: 2px solid #B6D2EB;
  padding: 0;
  line-height: 40px;
  padding: 0 200px;
  padding-top: 5px;
  text-align: center;
  p{
    display: inline-block;
    margin-right: 22px;
    &:nth-child(3n){
      margin-right: 0;
    }
  }
}
</style>
