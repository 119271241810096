import axios from 'axios'
import { Message } from 'element-ui'
import Router from '@/router/index.js'
// import * as comm from '../utils/common.js'
// import sha256 from 'js-sha256'
export let host = window.location.origin
import qs from 'qs'
// console.log(host)
if (host.indexOf('localhost') > -1 || host.indexOf('192.168.10.86') > -1) {
  host = 'http://192.168.11.15'
  // host = 'http://wangyuelawyer.c'
}
// const secret = 'p1LzzhHrS0bzoSRhTKNPJnCF/TcJtV4/Os/3pbGdhxvipVdfb3B318Br/Dln4MneE4faWx3KSy2R3LEeHvkF5nGj1vXfXA+eSgWbhfl4goucLba6CpPz4y7xzio6ARkNRa+AOBmrBxeSDdvFRxpSTu2uQ9eizPPIbSuwl/q7fPA8CQa0Q8RsToJhqDnYQdxsv8RJnS1qZrPN/2jxRfUBAW82ueXG+vevD2SmleOhbtgCiYoXKKgXUOvOTgUH0u+mGdqudLFaZSpzDsQB8n5RsojNwXEkJL5wlbAdyAQn64zTj3mMKnHmmpJwsorKzD8DO51VGOty17w/E8C9jj4Cgw=='
const secret = '60b06f524d8cccbb777063a7c87c9965'
function isForm(str) {
  if (/^[\w-]+=[\w-]+(&[\w-]+=[\w-]+)*$/.test(str)) {
    return true
  }
  return false
}

// 创建axios实例
export const htServer = axios.create({
  baseURL: host + '/api',
  // serverUrl: htServerUrl,
  withCredentials: false
})
htServer.defaults.withCredentials = true
// request 拦截器
htServer.interceptors.request.use(
  config => {
    // const _token = comm.common.getCookie('token')
    // // console.log(_token)
    // const _times = new Date().getTime()
    // const _random = comm.common.getRandom(4)
    /* if (config.data) {
      config.data.time = _times + _random;
    }*/
    if (!config.data) {
      config.data = qs.stringify({
        secret: secret
      })
    } else {
      if (isForm(config.data)) {
        const data = qs.parse(config.data)
        data.secret = secret
        config.data = qs.stringify(data)
      } else {
        config.data.secret = secret
      }
    }
    config.headers.secret = secret
    config.withCredentials = true // 允许携带token ,这个是解决跨域产生的相关问题
    // config.timeout = 15000;
    // if (comm.common.getCookie('token') !== undefined) {
    //   config.headers.token = _token
    //   // config.headers["auth-token"] = _token;
    //   config.headers.sign = sha256(_token + '3d58273e32d00935ce6cb7168231103c' + _times + _random)
    //   config.headers.time = _times + _random
    // }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)
// response 拦截器
htServer.interceptors.response.use(
  (res) => {
    if (res.data) {
      const data = res.data
      if (!data.code) {
        return data // --直接读取文件二进制流 --
      }
      // int 200 我们自己的接口，"200"是第三方视频接口  10000四层登录  "0"乐橙云 (!data.code && data)录播读取json文件
      if (data.code === 200 || data.code === 1 || data.code === 3 || data.code === 10000 || (data.result && data.result.code && data.result.code === 0) || (!data.code && data)) {
        return data
      } else if (data.code === 401) {
        Message({ showClose: true, type: 'error', message: '没有权限，请联系管理员' })
        return 'apiError'
      } else if (data.code === 506) {
        // console.log(12312)
        Message({ showClose: true, type: 'error', message: '时效已过期，请重新登录' })
        Router.push('/login')// 登录
        return
      } else if (data.code === 507) {
        Message({ showClose: true, type: 'error', message: '异地登陆，注意密码安全!' })
        Router.push('/login')// 登录
        return
      } else {
        if (data.message && data.message.indexOf('sql语法有误') !== -1) { // 智慧大屏 自定义数据集 不让直接返回错误
          return res
        }
        if (data.message && data.message.indexOf('数据点有误') !== -1) { // 设备医生下控
          return res
        }
        Message({ showClose: true, type: 'error', message: data.message || data.msg })
        return 'apiError'
      }
    }
  },
  (error) => {
    var originalRequest = error.config
    if (error.code === 'ECONNABORTED' && error.message.indexOf('timeout') !== -1 && !originalRequest._retry) {
      originalRequest._retry = true
      return axios.request(originalRequest)
    }
    if (error.response) {
      // 506 token问题登出
      if (error.response.data.code === 506) {
        Message({ showClose: true, type: 'error', message: error.response.data.message })
        Router.push('/login')// 登录
        return
      } else if (error.response.data.code === 507) {
        // 异地登陆
        Message({ showClose: true, type: 'error', message: error.response.data.message })
        Router.push('/login')// 登录
        return
      }

      if (error.response.status === 401) {
        Message({ showClose: true, type: 'error', message: '登录已失效' })
        Router.push('/login')// 登录
        return
      } else {
        Message({ showClose: true, type: 'error', message: error })
      }
    }
  }
)
// response拦截器
// htServer.interceptors.response.use(
//   (res) => {
//     const data = res.data
//     return data
//   },
//   () => {
//     Message({ showClose: true, type: 'error', message: '请求超时' })
//   }
// )
