import Vue from 'vue'
import VueRouter from 'vue-router'

import Store from '../store'
import Layout from '@/components/Layout/main.vue'

import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'
Vue.use(VueRouter)

// 获取路由文件
const componentList = {
}
// 使用require.context()遍历指定目录下的.vue文件
// const viewsPath =
const context = require.context('@/views', true, /\.vue$/)
context.keys().forEach(file => {
  // 获取组件配置
  const componentConfig = context(file)

  // 转换文件名为路由名
  const path = file.replace(/^\.\/(.*)\.\w+$/, '$1')
  const splitPath = path.split('/')
  const routeName = upperFirst(camelCase(splitPath.pop()))

  // 创建路由配置
  const routeConfig = {
    path: `/${routeName.toLowerCase()}`,
    name: routeName,
    component: componentConfig.default || componentConfig
  }
  // console.log(splitPath.pop())
  // 配置文件列表
  componentList[`/views/${path}`] = routeConfig.component
  componentList[`${path}`] = routeConfig.component
})
// console.log(componentList)
const defaultRoutes = [
  {
    path: '/article/:content_id',
    component: () => import('@/views/article.vue')
  }
]
const routes = [
  {
    path: '/',
    name: 'Index',
    component: Layout,
    redirect: '/home',
    children: [
      ...defaultRoutes
      // 首页
      // {
      //   path: '/',
      //   component: () => import('@/views/index.vue')
      // },
      // // 法律法规
      // {
      //   path: '/lawsRegulations',
      //   component: () => import('@/views/lawsRegulations.vue')
      // }
    ]
  }
]
const router = new VueRouter({ ...routes })
Store.dispatch('getNavList', (data) => {
  data.forEach((item, i) => {
    if (i === 0) {
      routes[0].redirect = '/' + item.resources_code
    }
    // 判断是否为文章列表页
    if (item.resources_url && item.resources_url.indexOf('acticleList') > -1) {
      routes[0].children.push({
        path: '/acticleList/:resourceCode',
        component: componentList[item.resources_url]
      })
    } else {
      routes[0].children.push({
        path: '/' + item.resources_code,
        // component: () => import(`@/a${item.resources_url}.vue`)
        component: componentList[item.resources_url || item.resources_code]
      })
    }
  })
  routes.forEach(item => {
    router.addRoute(item)
  })
})
// getAllMenu().then(res => {
//   // console.log(res)
//   res.data.forEach((item, i) => {
//     if (i === 0) {
//       routes[0].redirect = '/' + item.resources_code
//     }
//     routes[0].children.push({
//       path: '/' + item.resources_code,
//       component: componentList[item.resources_url || item.resources_code]
//     })
//   })
//   router.addRoutes(routes)
// })

router.beforeEach((to, from, next) => {
  // beforeEach是router的钩子函数，在进入路由前执行
  // if (to.name !== 'Login' && !common.getCookie('token')) {
  //   next({ path: '/login' })
  // }

  if (to.meta.title) {
    // 判断是否有标题
    //  console.log(to.meta.title)
    document.title = to.meta.title
  } else {
    document.title = '山东望岳律师事务所'
  }

  next()
})
export default router
